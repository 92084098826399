import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function HalfwayUp() {
  return (
    <Layout>
      <Helmet title="Halfway Up | The Brook and the Bluff" />

      <h1>Halfway Up</h1>

      <h2>The Brook and the Bluff</h2>

      <h4>Key: C</h4>

      <h4>Tempo: 82</h4>

      <Link to="/">List</Link>

      <Columns columns="3">
        <Verse>
          <p>
            <Ch>Am</Ch>Standing <Ch>C</Ch>out on glaciered <Ch>F</Ch>lakes
          </p>
          <p>
            <Ch>Am</Ch>You <Ch>C</Ch>locked me out for <Ch>F</Ch>days
          </p>
          <p>
            <Ch>Am</Ch>Pointed <Ch>C</Ch>lens, you turned your <Ch>F</Ch>face
          </p>
          <p>
            We could <Ch>Am</Ch>never feel comfor<Ch>C</Ch>table in this{" "}
            <Ch>F</Ch>way
          </p>
        </Verse>
        <Chorus>
          <p>
            When <Ch>Am</Ch>I was every<Ch>G</Ch>thing that you<Ch>Em</Ch> wan
            <Ch>F</Ch>ted
          </p>
          <p>
            And <Ch>Am</Ch>I was on the <Ch>G</Ch>path to being<Ch>Em</Ch> hon
            <Ch>F</Ch>est
          </p>
          <p>
            I <Ch>Am</Ch>stopped when we were <Ch>G</Ch>halfway up in<Ch>Em</Ch>{" "}
            Hall<Ch>F</Ch>statt
          </p>
          <p>
            And it <Ch>Am</Ch>felt as we were <Ch>G</Ch>living in our<Ch>Em</Ch>{" "}
            for<Ch>F</Ch>feits
          </p>
        </Chorus>
        <Verse>
          <p>
            <Ch>Am</Ch>Hollowed <Ch>C</Ch>ground, my place to <Ch>F</Ch>fame
          </p>
          <p>
            <Ch>Am</Ch>Middle of the spring but it's <Ch>C</Ch>cold in the{" "}
            <Ch>F</Ch>bed we made
          </p>
          <p>
            To <Ch>Am</Ch>opposite ends <Ch>C</Ch>we gravi<Ch>F</Ch>tate
          </p>
          <p>
            You could <Ch>Am</Ch>never feel <Ch>C</Ch>warm enough to stay
          </p>
          <p>
            I <Ch>F</Ch>wanna hear you say...
          </p>
        </Verse>
        <Chorus>
          <p>
            When <Ch>Am</Ch>I was every<Ch>G</Ch>thing that you<Ch>Em</Ch> wan
            <Ch>F</Ch>ted
          </p>
          <p>
            And <Ch>Am</Ch>I was on the <Ch>G</Ch>path to being<Ch>Em</Ch> hon
            <Ch>F</Ch>est
          </p>
          <p>
            I <Ch>Am</Ch>stopped when we were <Ch>G</Ch>halfway up in<Ch>Em</Ch>{" "}
            Hall<Ch>F</Ch>statt
          </p>
          <p>
            And it <Ch>Am</Ch>felt as we were <Ch>G</Ch>living in our<Ch>Em</Ch>{" "}
            for<Ch>F</Ch>feits
          </p>
        </Chorus>
        <Bridge>
          <p>
            <b>Am C F (x8)</b>
          </p>
        </Bridge>
        <Chorus>
          <p>
            When <Ch>Am</Ch>I was every<Ch>G</Ch>thing that you<Ch>Em</Ch> wan
            <Ch>F</Ch>ted
          </p>
          <p>
            And <Ch>Am</Ch>I was on the <Ch>G</Ch>path to being<Ch>Em</Ch> hon
            <Ch>F</Ch>est
          </p>
          <p>
            I <Ch>Am</Ch>stopped when we were <Ch>G</Ch>halfway up in<Ch>Em</Ch>{" "}
            Hall<Ch>F</Ch>statt
          </p>
          <p>
            And it <Ch>Am</Ch>felt as we were <Ch>G</Ch>living in our<Ch>Em</Ch>{" "}
            for<Ch>F</Ch>feits
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
